export const trackEvent = (action, category, label) => {
    window.gtag('event', action, {
      'event_category': category,
      'event_label': label
    });
  };

  export const trackReferrer = () => {
    const referrer = document.referrer;
    if (referrer) {
      window.gtag('event', 'referrer', {
        'event_category': 'Source',
        'event_label': referrer
      });
    }
  };