import { useState, useEffect } from "react";
import React from "react";
import InputMask from "react-input-mask";

class PhoneInput extends React.Component {
  render() {
    return (
      <InputMask
        mask="+375(99) 999 99 99"
        className="form-control"
        placeholder="Ваш телефон"
        alwaysShowMask={false}
        onFocus={this.props.onFocus}
        {...this.props}
      >
        {(inputProps) => <input {...inputProps} type="tel" />}
      </InputMask>
    );
  }
}

export default PhoneInput;

const initialState = {
  name: "",
  phone: "",
  message: "",
  agreeToTerms: false,
  errors: {
    name: "",
    phone: "",
    agreeToTerms: "",
  },
};

export const Contact = (props) => {
  const [{ name, phone, message, agreeToTerms, errors }, setState] = useState(initialState);
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
      errors: { ...prevState.errors, [name]: "" },
    }));
    setAlert({ ...alert, visible: false });
  };

  useEffect(() => {
    if (alert.visible) {
      const timer = setTimeout(() => {
        setAlert({ ...alert, visible: false });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [alert]);

  const clearState = () => setState(initialState);

  const validateForm = () => {
    const newErrors = {};
    const lastChar = phone?.charAt(phone.length - 1);
    const isNumber = !isNaN(parseInt(lastChar));
    console.log(phone, phone?.length, isNumber);

    if (!name) newErrors.name = "Введите ваше имя";
    if (!isNumber) newErrors.phone = "Введите правильный номер телефона";
    if (!phone) newErrors.phone = "Введите ваш телефон";
    if (!agreeToTerms) newErrors.agreeToTerms = "Необходимо согласие";

    setState((prevState) => ({ ...prevState, errors: newErrors }));
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true);

    fetch("https://primefinance.by/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, phone, message }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.message === 'success') {
          setAlert({ visible: true, message: "Сообщение отправлено успешно!", type: "success" });
        } else {
          setAlert({ visible: true, message: "Ошибка отправки сообщения!", type: "danger" });
        }
        console.log(data?.message);
        setLoading(false);
        clearState();
      })
      .catch((error) => {
        console.error("Error:", error);
        setAlert({ visible: true, message: "Ошибка отправки сообщения!", type: "danger" });
        setLoading(false);
      });
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Получить консультацию</h2>
                <p>Задайте свой вопрос нашему кредитному эксперту</p>
              </div>
              <form name="sentMessage" style={{paddingTop: 0}} validate onSubmit={handleSubmit}>
              {/* <div style={{ height: '52px', marginBottom: '20px'}}> Reserve space for alert */}
                {alert.visible && (
                  <div className={`alert alert-${alert.type}`} role="alert">
                    {alert.message}
                  </div>
                )}
                {/* </div> */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Ваше имя"
                        autoComplete="off"
                        onChange={handleChange}
                        value={name}
                      />
                      <p
                        className="help-block text-danger"
                        style={{
                          height: "24px",
                          visibility: errors.name ? "visible" : "hidden",
                        }}
                      >
                        {errors.name}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <PhoneInput
                        id="phone"
                        autoComplete="off"
                        value={phone}
                        onChange={(e) =>
                          setState((prevState) => ({
                            ...prevState,
                            phone: e.target.value,
                            errors: { ...prevState.errors, phone: "" },
                          }))
                        }
                      />
                      <p
                        className="help-block text-danger"
                        style={{
                          visibility: errors.phone ? "visible" : "hidden",
                        }}
                      >
                        {errors.phone}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    autoComplete="off"
                    className="form-control comment"
                    rows="5"
                    placeholder="Кратко опишите суть вопроса"
                    onChange={handleChange}
                    value={message}
                  ></textarea>
                </div>
                <div className="form-group">
                <div className="terms-and-conditions-wrapper">
                  <input
                    type="checkbox"
                    id="agreeToTerms"
                    name="agreeToTerms"
                    onChange={handleChange}
                    checked={agreeToTerms}
                  />
                  <label htmlFor="agreeToTerms" className="terms-and-conditions">
                    Согласен со сбором, обработкой, распространением, предоставлением и хранением персональных данных, ознакомлен с политикой конфиденциальности
                  </label>
                  </div>
                  <p
                    className="help-block text-danger"
                    style={{
                      height: "24px",
                      visibility: errors.agreeToTerms ? "visible" : "hidden",
                    }}
                  >
                    {errors.agreeToTerms}
                  </p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg" disabled={loading}>
                  {loading ? "Отправка..." : "Отправить"}
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Контакты</h3>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Телефон
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Prime Finance 
          </p>
        </div>
      </div>
    </div>
  );
};
