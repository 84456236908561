import React from "react";
import { ReactComponent as LogoIcon } from "../logo.svg";
import { trackEvent } from '../utils/analytics';

export const Navigation = (props) => {
  const handleClick = (name) => {
    trackEvent('click', 'Navigation', name);
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <LogoIcon />
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#features" onClick={() => handleClick('Преимущества')} className="page-scroll">
                Преимущества
              </a>
            </li>
            <li>
              <a href="#about" onClick={() => handleClick('О нас')} className="page-scroll">
                О нас
              </a>
            </li>
            <li>
              <a href="#services" onClick={() => handleClick('Услуги')} className="page-scroll">
                Услуги
              </a>
            </li>
            <li>
              <a href="#docs" onClick={() => handleClick('Документы')} className="page-scroll">
                Документы
              </a>
            </li>
            {/* <li>
              <a href="#prices" className="page-scroll">
                Цены
              </a>
            </li> */}
            <li>
              <a href="#testimonials" onClick={() => handleClick('Отзывы')} className="page-scroll">
                Отзывы
              </a>
            </li>
            <li>
              <a href="#contact" onClick={() => handleClick('Контакты')} className="page-scroll">
                Контакты
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
