// import React, { useState } from "react";
// import InputMask from "react-input-mask";
// import { trackEvent } from '../utils/analytics';

// const PhoneInput = (props) => {
//   return (
//     <InputMask
//       mask="+375(99) 999 99 99"
//       className="form-control text-input"
//       placeholder="Ваш телефон"
//       alwaysShowMask={false}
//       {...props}
//     >
//       {(inputProps) => <input {...inputProps} type="tel" />}
//     </InputMask>
//   );
// };

// const initialState = {
//   name: "",
//   phoneNumber: "",
//   errors: {
//     name: "",
//     phoneNumber: "",
//   },
// };

// export const Header = (props) => {
//   const [{ name, phoneNumber, errors }, setState] = useState(initialState);

//   const [showModal, setShowModal] = useState(false);
//   const [currentQuestion, setCurrentQuestion] = useState(1);
//   const [answers, setAnswers] = useState({});
//   const [consentGiven, setConsentGiven] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [alert, setAlert] = useState({ visible: false, message: "" });

//   const questions = [
//     {
//       id: 1,
//       text: "Какой вид кредита Вас интересует ?",
//       options: ["Кредит на недвижимость", "Потребительский кредит"],
//     },
//     {
//       id: 2,
//       text: "Какая сумма кредита Вам необходима ?",
//       options: ["До 50 000 руб", "До 100 000 руб", "Больше 100 000 руб"],
//     },
//     {
//       id: 3,
//       text: "Какой вид кредита на недвижимость Вас интересует ?",
//       options: [
//         "Покупка квартиры/дома",
//         "Строительство квартиры",
//         "Строительство дома",
//         "Мне нужен потребительский кредит",
//       ],
//     },
//     {
//       id: 4,
//       text: "Укажите Ваш возраст ?",
//       options: [
//         "до 25 лет",
//         "25 - 35 лет",
//         "35 - 45 лет",
//         "45 - 55 лет",
//         "старше 55 лет",
//       ],
//     },
//     {
//       id: 5,
//       text: "Работаете ли Вы сейчас ?",
//       options: [
//         "Да, более 3 месяцев",
//         "Да, менее 3 месяцев",
//         "Пенсионер/Студент",
//         "ИП/Самозанятый",
//         "В декрете",
//         "Не работаю",
//       ],
//     },
//     {
//       id: 6,
//       text: "Укажите Ваш ежемесячный доход?",
//       options: ["до 1000 руб", "до 2000 руб", "до 3000 руб", "свыше 3000 руб"],
//     },
//     {
//       id: 7,
//       text: "Имеются ли у Вас просрочки по кредитам?",
//       options: ["Отсутствуют", "Имеются текущие", "Были ранее"],
//     },
//   ];

//   const handleClose = () => {
//     setShowModal(false);
//     setCurrentQuestion(1);
//     setAnswers({});
//     setState(initialState);
//     setConsentGiven(false);
//     setLoading(false);
//     setAlert({ visible: false, message: "" });
//   };

//   const handleNext = () => {
//     if (
//       currentQuestion < questions.length &&
//       !answers[questions[currentQuestion - 1]?.text]
//     ) {
//       setAnswers((prevAnswers) => ({
//         ...prevAnswers,
//         [questions[currentQuestion - 1].text]:
//           questions[currentQuestion - 1].options[0],
//       }));
//     }
//     setCurrentQuestion((prevQuestion) => prevQuestion + 1);
//   };

//   const handlePrev = () => {
//     setCurrentQuestion((prevQuestion) => prevQuestion - 1);
//   };

//   const handleAnswerChange = (event) => {
//     const { name, value } = event.target;
//     setAnswers((prevAnswers) => ({
//       ...prevAnswers,
//       [name]: value,
//     }));
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setState((prevState) => ({
//       ...prevState,
//       [name]: value,
//       errors: { ...prevState.errors, [name]: "" },
//     }));
//   };

//   const handleFocus = (e) => {
//     const { name } = e.target;
//     setState((prevState) => ({
//       ...prevState,
//       errors: { ...prevState.errors, [name]: "" },
//     }));
//   };

//   const validateForm = () => {
//     const newErrors = {};
//     const lastChar = phoneNumber?.charAt(phoneNumber.length - 1);
//     const isNumber = !isNaN(parseInt(lastChar));

//     if (!name) newErrors.name = "Введите ваше имя";
//     if (!isNumber) newErrors.phoneNumber = "Введите правильный номер телефона";
//     if (!phoneNumber) newErrors.phoneNumber = "Введите ваш телефон";
//     if (!consentGiven) newErrors.consent = "Необходимо согласие";

//     setState((prevState) => ({ ...prevState, errors: newErrors }));

//     return Object.keys(newErrors).length === 0;
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (!validateForm()) return;

//     setLoading(true);

//     fetch("https://primefinance.by/send", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ name, phone: phoneNumber, message: answers }),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data?.message === "success") {
//           setLoading(false);
//           setAlert({
//             visible: true,
//             message: "Мы обязательно с Вами свяжемся в кратчайшие сроки!",
//             type: "success",
//           }); // Set success type
//           setTimeout(() => handleClose(), 3000);
//         } else {
//           setAlert({
//             visible: true,
//             message: "Ошибка отправки сообщения!",
//             type: "danger",
//           }); // Set danger type
//           setLoading(false);
//         }
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//         setAlert({
//           visible: true,
//           message: "Ошибка отправки сообщения!",
//           type: "danger",
//         }); // Set danger type
//         setLoading(false);
//       });
//   };

//   const openModal = () => {
//     const initialAnswers = questions.reduce((acc, question) => {
//       acc[question.text] = question.options[0];
//       return acc;
//     }, {});
//     trackEvent('click', 'Button', 'Подобрать кредит');
//     setAnswers(initialAnswers);
//     setShowModal(true);
//   };

//   const handleCheckboxChange = (e) => {
//     setConsentGiven(e.target.checked);
//     if (e.target.checked) {
//       setState((prevState) => ({
//         ...prevState,
//         errors: { ...prevState.errors, consent: "" },
//       }));
//     }
//   };

//   return (
//     <>
//       <header id="header">
//         <div className="intro">
//           <div className="overlay">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-8 col-md-offset-2 intro-text">
//                   <h1>
//                     {props.data ? props.data.title : "Loading"}
//                     <span></span>
//                   </h1>
//                   <p>{props.data ? props.data.paragraph : "Loading"}</p>
//                   <a
//                     href="#features"
//                     className="btn btn-custom btn-lg page-scroll"
//                     style={{ margin: "0 20px 20px 20px" }}
//                   >
//                     Подробнее
//                   </a>{" "}
//                   <a
//                     onClick={openModal}
//                     className="btn btn-custom btn-custom-main pulsing-button btn-lg page-scroll"
//                     style={{ margin: "0 20px 20px 20px" }}
//                   >
//                     Подобрать кредит
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </header>
//       {showModal && (
//         <>
//           <div className="modal-backdrop fade in" />
//           <div className="modal fade in" tabIndex="-1" role="dialog">
//             <div className="modal-dialog" role="document">
//               <div className="modal-content">
//                 <div className="modal-header">
//                   <button type="button" className="close" onClick={handleClose}>
//                     <span>&times;</span>
//                   </button>
//                 </div>
//                 <div className="modal-body">
//                   {alert.visible && (
//                     <div className={`alert alert-${alert.type}`}>
//                       {alert.message}
//                     </div>
//                   )}

//                   {currentQuestion <= questions.length ? (
//                     <>
//                       <p className="title">
//                         {questions[currentQuestion - 1].text}
//                       </p>
//                       <form>
//                         {questions[currentQuestion - 1].options.map(
//                           (option, index) => (
//                             <div
//                               className="form-check custom-radio"
//                               key={index}
//                             >
//                               <input
//                                 type="radio"
//                                 id={`option${index}`}
//                                 name={questions[currentQuestion - 1].text}
//                                 value={option}
//                                 checked={
//                                   answers[
//                                     questions[currentQuestion - 1].text
//                                   ] === option
//                                 }
//                                 onChange={handleAnswerChange}
//                                 className="form-check-input custom-control-input"
//                               />
//                               <label
//                                 htmlFor={`option${index}`}
//                                 className="form-check-label custom-control-label"
//                               >
//                                 {option}
//                               </label>
//                             </div>
//                           )
//                         )}
//                       </form>
//                     </>
//                   ) : (
//                     <>
//                       <p className="title">
//                         Введите свои данные для обратной связи
//                       </p>
//                       <form>
//                         <div className="form-group">
//                           <input
//                             type="text"
//                             className="form-control text-input"
//                             placeholder="Ваше имя"
//                             autoComplete="off"
//                             name="name"
//                             value={name}
//                             onChange={handleChange}
//                             onFocus={handleFocus}
//                           />
//                           {errors.name && (
//                             <p className="help-block text-danger">
//                               {errors.name}
//                             </p>
//                           )}
//                         </div>
//                         <div className="form-group">
//                           <PhoneInput
//                             name="phoneNumber"
//                             autoComplete="off"
//                             value={phoneNumber}
//                             onChange={handleChange}
//                             onFocus={handleFocus}
//                           />
//                           {errors.phoneNumber && (
//                             <p className="help-block text-danger">
//                               {errors.phoneNumber}
//                             </p>
//                           )}
//                         </div>
//                         <div className="form-group">
//                           <div className="terms-and-conditions-wrapper">
//                             <input
//                               type="checkbox"
//                               className="form-check-input"
//                               id="consentCheckbox"
//                               checked={consentGiven}
//                               onChange={handleCheckboxChange}
//                             />
//                             <label
//                               className="terms-and-conditions"
//                               htmlFor="consentCheckbox"
//                             >
//                               Согласен со сбором, обработкой, распространением,
//                               предоставлением и хранением персональных данных,
//                               ознакомлен с политикой конфиденциальности
//                             </label>
//                           </div>
//                           {errors.consent && (
//                             <p className="help-block text-danger">
//                               {errors.consent}
//                             </p>
//                           )}
//                         </div>
//                       </form>
//                     </>
//                   )}
//                 </div>
//                 <div className="modal-footer">
//                   {currentQuestion > 1 && (
//                     <button
//                       type="button"
//                       className="btn btn-custom-back"
//                       onClick={handlePrev}
//                     >
//                       Назад
//                     </button>
//                   )}
//                   {currentQuestion < questions.length + 1 ? (
//                     <button
//                       type="button"
//                       className="btn btn-custom btn-xs"
//                       onClick={handleNext}
//                     >
//                       Далее
//                     </button>
//                   ) : (
//                     <button
//                       type="button"
//                       className="btn btn-custom btn-xs"
//                       onClick={handleSubmit}
//                       disabled={loading}
//                     >
//                       {loading ? "Отправка..." : "Отправить"}
//                     </button>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </>
//       )}
//     </>
//   );
// };



import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { trackEvent } from '../utils/analytics';

const PhoneInput = (props) => {
  return (
    <InputMask
      mask="+375(99) 999 99 99"
      className="form-control text-input"
      placeholder="Ваш телефон"
      alwaysShowMask={false}
      {...props}
    >
      {(inputProps) => <input {...inputProps} type="tel" />}
    </InputMask>
  );
};

const initialState = {
  name: "",
  phoneNumber: "",
  errors: {
    name: "",
    phoneNumber: "",
  },
};

export const Header = (props) => {
  const [{ name, phoneNumber, errors }, setState] = useState(initialState);

  const [showModal, setShowModal] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [answers, setAnswers] = useState({});
  const [consentGiven, setConsentGiven] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ visible: false, message: "" });
  const [currentFormStep, setCurrentFormStep] = useState(1);

  const questions = [
    {
      id: 1,
      text: "Какой вид кредита Вас интересует ?",
      options: ["Кредит на недвижимость", "Потребительский кредит"],
    },
    {
      id: 2,
      text: "Какая сумма кредита Вам необходима ?",
      options: ["До 50 000 руб", "До 100 000 руб", "Больше 100 000 руб"],
    },
    {
      id: 3,
      text: "Какой вид кредита на недвижимость Вас интересует ?",
      options: [
        "Покупка квартиры/дома",
        "Строительство квартиры",
        "Строительство дома",
        "Мне нужен потребительский кредит",
      ],
    },
    {
      id: 4,
      text: "Укажите Ваш возраст ?",
      options: [
        "до 25 лет",
        "25 - 35 лет",
        "35 - 45 лет",
        "45 - 55 лет",
        "старше 55 лет",
      ],
    },
    {
      id: 5,
      text: "Работаете ли Вы сейчас ?",
      options: [
        "Да, более 3 месяцев",
        "Да, менее 3 месяцев",
        "Пенсионер/Студент",
        "ИП/Самозанятый",
        "В декрете",
        "Не работаю",
      ],
    },
    {
      id: 6,
      text: "Укажите Ваш ежемесячный доход?",
      options: ["до 1000 руб", "до 2000 руб", "до 3000 руб", "свыше 3000 руб"],
    },
    {
      id: 7,
      text: "Имеются ли у Вас просрочки по кредитам?",
      options: ["Отсутствуют", "Имеются текущие", "Были ранее"],
    },
  ];

  useEffect(() => {
    if (showModal) {
      setCurrentFormStep(currentQuestion);
      window.history.replaceState({}, '', `?form=${currentQuestion}`);
    }
  }, [showModal, currentQuestion]);

  const handleClose = () => {
    setShowModal(false);
    setCurrentQuestion(1);
    setAnswers({});
    setState(initialState);
    setConsentGiven(false);
    setLoading(false);
    setAlert({ visible: false, message: "" });
    setCurrentFormStep(1);
    window.history.replaceState({}, '', '/');
  };

  const handleNext = () => {
    if (
      currentQuestion < questions.length &&
      !answers[questions[currentQuestion - 1]?.text]
    ) {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [questions[currentQuestion - 1].text]:
          questions[currentQuestion - 1].options[0],
      }));
    }
    setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    setCurrentFormStep(currentQuestion + 1);
    window.history.replaceState({}, '', `?form=${currentQuestion + 1}`);
  };

  const handlePrev = () => {
    setCurrentQuestion((prevQuestion) => prevQuestion - 1);
    setCurrentFormStep(currentQuestion - 1);
    window.history.replaceState({}, '', `?form=${currentQuestion - 1}`);
  };

  const handleAnswerChange = (event) => {
    const { name, value } = event.target;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: { ...prevState.errors, [name]: "" },
    }));
  };

  const handleFocus = (e) => {
    const { name } = e.target;
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, [name]: "" },
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    const lastChar = phoneNumber?.charAt(phoneNumber.length - 1);
    const isNumber = !isNaN(parseInt(lastChar));

    if (!name) newErrors.name = "Введите ваше имя";
    if (!isNumber) newErrors.phoneNumber = "Введите правильный номер телефона";
    if (!phoneNumber) newErrors.phoneNumber = "Введите ваш телефон";
    if (!consentGiven) newErrors.consent = "Необходимо согласие";

    setState((prevState) => ({ ...prevState, errors: newErrors }));

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true);

    fetch("https://primefinance.by/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, phone: phoneNumber, message: answers }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.message === "success") {
          setLoading(false);
          setAlert({
            visible: true,
            message: "Мы обязательно с Вами свяжемся в кратчайшие сроки!",
            type: "success",
          }); // Set success type
          setTimeout(() => handleClose(), 3000);
        } else {
          setAlert({
            visible: true,
            message: "Ошибка отправки сообщения!",
            type: "danger",
          }); // Set danger type
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setAlert({
          visible: true,
          message: "Ошибка отправки сообщения!",
          type: "danger",
        }); // Set danger type
        setLoading(false);
      });
  };

  const openModal = () => {
    const initialAnswers = questions.reduce((acc, question) => {
      acc[question.text] = question.options[0];
      return acc;
    }, {});
    trackEvent('click', 'Button', 'Подобрать кредит');
    setAnswers(initialAnswers);
    setShowModal(true);
  };

  const handleCheckboxChange = (e) => {
    setConsentGiven(e.target.checked);
    if (e.target.checked) {
      setState((prevState) => ({
        ...prevState,
        errors: { ...prevState.errors, consent: "" },
      }));
    }
  };

  return (
    <>
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h1>
                    {props.data ? props.data.title : "Loading"}
                    <span></span>
                  </h1>
                  <p>{props.data ? props.data.paragraph : "Loading"}</p>
                  <a
                    href="#features"
                    className="btn btn-custom btn-lg page-scroll"
                    style={{ margin: "0 20px 20px 20px" }}
                  >
                    Подробнее
                  </a>{" "}
                  <a
                    onClick={openModal}
                    className="btn btn-custom btn-custom-main pulsing-button btn-lg page-scroll"
                    style={{ margin: "0 20px 20px 20px" }}
                  >
                    Подобрать кредит
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {showModal && (
        <>
          <div className="modal-backdrop fade in" />
          <div className="modal fade in" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" onClick={handleClose}>
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {alert.visible && (
                    <div className={`alert alert-${alert.type}`}>
                      {alert.message}
                    </div>
                  )}

                  {currentQuestion <= questions.length ? (
                    <>
                      <p className="title">
                        {questions[currentQuestion - 1].text}
                      </p>
                      <form>
                        {questions[currentQuestion - 1].options.map(
                          (option, index) => (
                            <div
                              className="form-check custom-radio"
                              key={index}
                            >
                              <input
                                type="radio"
                                id={`option${index}`}
                                name={questions[currentQuestion - 1].text}
                                value={option}
                                checked={
                                  answers[
                                    questions[currentQuestion - 1].text
                                  ] === option
                                }
                                onChange={handleAnswerChange}
                                className="form-check-input custom-control-input"
                              />
                              <label
                                htmlFor={`option${index}`}
                                className="form-check-label custom-control-label"
                              >
                                {option}
                              </label>
                            </div>
                          )
                        )}
                      </form>
                    </>
                  ) : (
                    <>
                      <p className="title">
                        Введите свои данные для обратной связи
                      </p>
                      <form>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control text-input"
                            placeholder="Ваше имя"
                            autoComplete="off"
                            name="name"
                            value={name}
                            onChange={handleChange}
                            onFocus={handleFocus}
                          />
                          {errors.name && (
                            <p className="help-block text-danger">
                              {errors.name}
                            </p>
                          )}
                        </div>
                        <div className="form-group">
                          <PhoneInput
                            name="phoneNumber"
                            autoComplete="off"
                            value={phoneNumber}
                            onChange={handleChange}
                            onFocus={handleFocus}
                          />
                          {errors.phoneNumber && (
                            <p className="help-block text-danger">
                              {errors.phoneNumber}
                            </p>
                          )}
                        </div>
                        <div className="form-group">
                          <div className="terms-and-conditions-wrapper">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="consentCheckbox"
                              checked={consentGiven}
                              onChange={handleCheckboxChange}
                            />
                            <label
                              className="terms-and-conditions"
                              htmlFor="consentCheckbox"
                            >
                              Согласен со сбором, обработкой, распространением,
                              предоставлением и хранением персональных данных,
                              ознакомлен с политикой конфиденциальности
                            </label>
                          </div>
                          {errors.consent && (
                            <p className="help-block text-danger">
                              {errors.consent}
                            </p>
                          )}
                        </div>
                      </form>
                    </>
                  )}
                </div>
                <div className="modal-footer">
                  {currentQuestion > 1 && (
                    <button
                      type="button"
                      className="btn btn-custom-back"
                      onClick={handlePrev}
                    >
                      Назад
                    </button>
                  )}
                  {currentQuestion < questions.length + 1 ? (
                    <button
                      type="button"
                      className="btn btn-custom btn-xs"
                      onClick={handleNext}
                    >
                      Далее
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-custom btn-xs"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {loading ? "Отправка..." : "Отправить"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

